// Vue
import Vue from "vue";
Vue.config.productionTip = false;

// Router (VueRouter)
import router from "./router.js";

// Vue head
import VueHead from "vue-head";
Vue.use(VueHead);

// Store (vuex)
import store from "./store.js";

// NProgress
import { NProgress } from "./nprogress.js";

// Axios
import { appConfig } from "./config.js";
import { HTTP } from "./http-common.js";

// PWA
import "./registerServiceWorker.js";

// Font Loader (FontFaceObserver)
import themeFontLoader from "./libs/font-loader.js";
themeFontLoader(["Roboto", "Roboto Mono", "Montserrat"]);

// Notifications
import Toasted from "vue-toasted";
import { toastedDefaults, toastedSuccess, toastedError, toastedDefaultConfirm } from "./libs/toasted-options.js";

Vue.use(Toasted);

Vue.toasted.register(
  "appError",
  (payload) => {
    // if there is no message passed show default message
    if (!payload.message) {
      return "Une erreur inconnue est survenue !";
    }
    // if there is a message show it with the message
    // ❗⚠ ⚠️
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedError
);

Vue.toasted.register(
  "appSuccess",
  (payload) => {
    // ✓
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedSuccess
);

Vue.toasted.register(
  "appHelp",
  (payload) => {
    // ✓
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedSuccess
);

Vue.toasted.register(
  "appInfo",
  (payload) => {
    return `<div class="message-wrapper">${payload.message}</div>`;
  },
  toastedDefaults
);

// tooltips
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

// dayjs
import Dayjs from "vue-dayjs";
Vue.use(Dayjs);

// Global Route Guards
// Les routes qui nécessitent une authentification ont une meta `authenticated` définie sur `true`
// Ces routes nécessitent que l'utilisateur soit **authentifié** (`store.userData` ne peut pas être indéfini)

router.beforeEach((to, from, next) => {
  // The user is logged-in, redirect from home to dashboard
  if ((to.name === "home" || to.name === "root" || to.name === "login") && !!store.state.userData && parseInt(store.state.userData.registration_complete) === 1) {
    next("/dashboard");
  }

  // no authentication needed
  if (!to.meta || !to.meta.authenticated) {
    next();
  }

  // route needs an authenticated user
  else {
    // Doit terminer l'inscription
    if (!store.state.userData || !store.state.userData.registration_complete) {
      let msg = "";
      let nextRoute = "/login";
      let query = new URLSearchParams();

      // L'utilisateur est connecté pour terminer son inscription
      if (!!store.state.userData && !store.state.userData.registration_complete) {
        Vue.toasted.global.appInfo({
          message: "Vous pouvez maintenant <strong>compléter votre profil</strong> pour terminer l'inscription.",
        });
        next("/register");
        return;
      }

      // Redirect to the requested URL after login
      if (to.name !== "root" && to.name !== "login") {
        query.append("redirect", to.fullPath);
      }

      // Le token est disponible mais pas les infos utilisateur : session expirée
      if (store.state.userToken) {
        msg = "Votre session a expirée, merci de vous connecter.";
        Vue.toasted.global.appError({
          message: msg,
        });
      }

      next(`${nextRoute}?${query.toString()}`);
    }

    // All good
    else {
      next();
    }
  }
});

// Vue filters
import { slugify } from "./libs/helpers";
Vue.filter("slugify", slugify);

// Frontend Monitoring (LogRocket)
import LogRocket from "logrocket";

if (!!appConfig.frontendMonitoring) {
  // Anamorphik @dev
  LogRocket.init("nbv53p/infovac");
  console.info("Frontend monitoring ON");
}

// Main App
import App from "./App.vue";

// auto track routes
Vue.use(router);

// Global event bus
import { EventBus } from "./event-bus.js";

new Vue({
  router,
  store,
  render: (h) => h(App),

  head: {
    title: function() {
      return {
        inner: "Infovac",
        complement: "Plateforme de téléexpertise",
      };
    },

    // Meta tags
    meta: [
      { name: "msapplication-TileColor", content: "#00aba9" },
      { name: "theme-color", content: "#ffffff" },
      {
        name: "description",
        content: "La solution de téléexpertise pour vos questions sur la vaccination.",
      },
    ],
    link: [
      {
        rel: "manifest",
        href: "/manifest.json",
      },
      {
        rel: "apple-touch-icon",
        href: "/apple-touch-icon.png",
        sizes: "32x32",
        type: "image/png",
      },
      {
        rel: "icon",
        href: "/favicon-32x32.png",
        sizes: "32x32",
        type: "image/png",
      },
      {
        rel: "icon",
        href: "/favicon-16x16.png",
        sizes: "16x16",
        type: "image/png",
      },
      {
        rel: "mask-icon",
        href: "/safari-pinned-tab.svg",
        color: "#229ad2",
      },

      // DNS Prefetch
      {
        rel: "prefetch",
        href: "//www.google-analytics.com",
      },

      // Preconnect
      {
        rel: "preconnect",
        href: "//googletagmanager.com",
      },

      // Preload fonts
      {
        rel: "preload",
        href: "/fonts/roboto-mono-v7-latin-regular.0c94e034.woff2",
        as: "font",
      },
      {
        rel: "preload",
        href: "/fonts/roboto-v20-latin_latin-ext-regular.c5bf51b6.woff2",
        as: "font",
      },
      {
        rel: "preload",
        href: "/fonts/montserrat-v14-latin-ext_latin-regular.20f97c00.woff2",
        as: "font",
      },
      {
        rel: "preload",
        href: "/fonts/montserrat-v14-latin-ext_latin-800.2b2c1f44.woff2",
        as: "font",
      },
    ],

    script: [
      {
        type: "text/javascript",
        // src: "https://cdnjs.cloudflare.com/ajax/libs/outdated-browser/1.1.5/outdatedbrowser.min.js",
        src: "/external/outdatedbrowser.min.js",
        async: true,
        body: true,
      },
    ],
  },

  created() {
    // Skip interceptors for the "login" route

    // Network / Authentication error handling
    HTTP.interceptors.response.use(
      (response) => response,
      (error) => {
        NProgress.done();

        console.info("Interceptor error (url,status)", error.config.url, error.response.status);

        // Login - return all errors to original promise (store/login.vue)
        // if (error.config.url === "login" && error.response.status === 403) {
        if (error.config.url === "login") {
          return Promise.reject(error.response);
        }

        // Auth error on request - Hijack promise response
        if (error.response && [401].indexOf(error.response.status) !== -1) {
          console.warn("AUTH ERROR (interceptor)");

          this.$toasted.clear();
          this.$toasted.global.appError({
            message: "Votre session a expirée, veuillez vous reconnecter.",
          });

          this.$store.dispatch("AUTH_LOGOUT").then(() => this.$router.push("/login"));

          return;
        }

        // TODO: network error
        // TODO: 404

        // return Promise.reject(error); // error.response?
        return Promise.reject(error.response);

        // Cancelling a promise?
        // https://github.com/axios/axios/issues/583
        // return new Promise(() => {});
      }
    );

    // Authentification : si un JWT est enregistré en localStorage, on le passe dans les headers de chaque requêtes.
    if (this.$store.state.userToken) HTTP.defaults.headers.common.Authorization = "Bearer " + this.$store.state.userToken;

    // Synchronisation du localStorage
    // L'application root surveille les mutations du Store (`subscribe`) et execute l'action STORAGE_SYNC pour mettre à jour le localStorage.
    this.$store.subscribe((mutation, state) => {
      this.$store
        .dispatch("STORAGE_SYNC", mutation)
        .then((response) => console.info("STORAGE_SYNC done"))
        .catch((error) => console.warn("STORAGE_SYNC error"));
    });

    // Auto login?
    if (this.$router.currentRoute.name !== "login") this.autoLoginUser();

    // dayjs Plugins
    require("dayjs/locale/fr");
    let isoWeeksInYear = require("dayjs/plugin/isoWeeksInYear");
    let isLeapYear = require("dayjs/plugin/isLeapYear");
    let weekday = require("dayjs/plugin/weekday");
    let isoWeek = require("dayjs/plugin/isoWeek");
    let advancedFormat = require("dayjs/plugin/advancedFormat");
    this.$dayjs.extend(isoWeek);
    this.$dayjs.extend(isoWeeksInYear);
    this.$dayjs.extend(isLeapYear);
    this.$dayjs.extend(weekday);
    this.$dayjs.extend(advancedFormat);
    this.$dayjs.locale("fr"); //.format()
  },

  mounted() {
    // Configure NProgress when the DOM is ready…
    NProgress.configure({ parent: ".app-header" });

    // GA track events
    // EventBus.$on("trackEvent", (action, label, value = 1) => {
    //   // console.log("EventBus", action, label);
    //   if (!appConfig.eventTracking) return false;
    //   this.$gtag.event(action, {
    //     event_category: "Application Infovac",
    //     event_label: label,
    //     value: value,
    //   });
    // });
  },

  computed: {
    // Utilisateur connecté ?
    isAuthenticated: function() {
      return !!this.$store.state.userToken && !!this.$store.state.userData && this.$store.state.userData.registration_complete;
    },
  },

  methods: {
    // Connexion automatique si un token est présent mais sans données utilisateur
    autoLoginUser: function() {
      if (!this.$store.state.userToken || !!this.$store.state.userData) return;
      console.info("autoLoginUser");

      this.$store
        .dispatch("USER_LOGIN")
        .then(() => this.$router.push("dashboard"))
        .catch((error) => {
          this.$router.push("login");

          // Le compte n'est pas activé (email opt-in)
          if (error.response.status === 403)
            return this.$toasted.global.appError({
              message: "<div>Votre compte n’est pas activé, <strong>vérifiez votre boite mail.</strong></div>",
            });
          // Erreur d'authentification
          else
            return this.$toasted.global.appError({
              message: "Impossible de vous connecter, <br>vérifiez votre identifiant et votre mot de passe !",
            });

          // Session expirée
          // this.$toasted.global.appError({message: "Votre session a expirée, veuillez vous reconnecter."});
        });
    },
  },
}).$mount("#app");
